<template>
	<div class="modal fade" id="delegateObjectModal" tabindex="-1" aria-labelledby="Выбор нового сотрудника для выбранного объекта" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span v-if="this.objects_сount > 1" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранных объектов
					</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранного объекта
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<SelectBox inputLabelClass="text-font-secondary mb-3"
							   inputId="new_who_work"
							   inputName="new_who_work"
							   inputLabel="Сотрудник:"
							   :inputOptions="this.employees_list"
							   :inputValue="this.employee_id"
							   inputClass="rounded-1 p-2"
							   inputPlaceholder="Выберите пользователя"
							   inputSearch="true"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setEmployee(value)" />
					
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						:class="{'disabled' : !(this.employee_id || this.employees_list)}"
						data-bs-dismiss="modal" 
						@click="this.initTransfer()">
						Передать
					</button>
				</div>
			</div>
		</div>
	</div>

	<ResultsModal id="transferObject"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"	/>

</template>

<script>
import SelectBox from "@/components/inputs/SelectBox";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import CommonService from "@/services/CommonService";

export default {
	name: "DelegateObjectModal",
	props: {
		objectsIds: { type: [Array, String] },
		employeesList: { type: Array }
	},
	data() {
		return {
			employees_list: (typeof this.employeesList !== "undefined") ? this.employeesList : [],
			employee_id: null,
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	components: {
		ResultsModal,
		SelectBox
	},
	methods: {
		resetData() {
			this.employees_list = [];
			this.employee_id = null;
			this.resultsModalState = {
				state: '',
				title: '',
				message: ''
			};
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		setEmployee(value) {

			CommonService.log('debug', 'setEmployee()', {employee_id: value});

			this.employee_id = value;
		},
		getEmployees() {

			let _this = this;
			return api.get('/common/employees', {
				params: {
					self_exclude: true,
					add_groups: false
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, employee]) => {
							list.push({
								value: employee.id.toString(),
								name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
							});
						});
					}

					if (typeof _this.$store !== "undefined")
						_this.$store.commit('setEmployeesListExcluded', list);

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getEmployees()::axios', error);

			});
		},
		initTransfer() {

			if (!CommonService.isEmpty(this.objects_ids) && this.employee_id) {
				api.put('/objects/transfer', {
					objects_ids: this.objects_ids,
					employee_id: this.employee_id
				}).then((response) => {
					CommonService.log('debug', 'initTransfer()::axios', {response});

					if (response.status == 200 && response.data.success) {
						this.resultsModalState.state = 'success';
						this.resultsModalState.title = 'OK';

						if (this.objects_сount > 1)
							this.resultsModalState.message = 'Объекты были успешно переданы другому сотруднику!';
						else
							this.resultsModalState.message = 'Объект был успешно передан другому сотруднику!';

						this.$emit('objectsTransfered', {objects_ids: this.objects_ids, employee_id: this.employee_id});
						this.resetData();
					} else {
						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';

						if (this.objects_сount > 1)
							this.resultsModalState.message = 'Объекты не были переданы другому сотруднику.';
						else
							this.resultsModalState.message = 'Объект не был передан другому сотруднику.';

					}

				}).catch(function (error) {

					CommonService.log('error', 'initTransfer()::axios', error);

					this.resultsModalState.state = 'error';
					this.resultsModalState.title = 'Произошла ошибка!';

					if (this.objects_сount > 1)
						this.resultsModalState.message = 'Объекты не были переданы.';
					else
						this.resultsModalState.message = 'Объект не был передан.';

				});
			} else {
				this.resultsModalState.state = 'error';
				this.resultsModalState.title = 'Ошибка!';
				this.resultsModalState.message = 'Необходимо выбрать хотя бы один объект/сотрудника для передачи.';
			}
		},
	},
	mounted() {

		if (!this.isEmpty(this.$store.getters.employeesListExcluded))
			this.employees_list = this.$store.getters.employeesListExcluded;
		else
			this.getEmployees().then(data => { this.employees_list = data });

		let modal = document.getElementById('delegateObjectModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.employee_id = null;
				_this.objects_ids = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				_this.employee_id = null;

				let target = event.relatedTarget
				if (typeof target !== "undefined") {
					_this.objects_ids = [];
					let object_id = target.getAttribute('data-bs-object-id');
					if (typeof object_id !== "undefined") {
						_this.objects_ids.push(object_id);
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('delegateObjectModal::show.bs.modal', {
						objects_ids: _this.objects_ids,
						employees_list: _this.employees_list
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		objects_сount() {
			return this.objects_ids.length
		},
		objects_ids() {
			return (typeof this.objectsIds !== "undefined") ? this.objectsIds : [];
		},
	},
	watch: {
		employee_id: function() {
			return this.employee_id;
		},
	}
}
</script>