<template>
	<div class="modal fade" id="tarifsEgrnModal" tabindex="-1" aria-labelledby="Тарифы получения выписок из ЕГРН" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-1">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Тарифы получения выписок из ЕГРН
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body d-flex flex-column p-1">
					<span class="mb-3 text-secondary">Здесь Вы можете выбрать подходящий пакет и заказать его. После заказа с Вами свяжется наш менеджер и расскажет дальнейшие действия.</span>
					<div class="d-flex flex-fill" style="margin-left: -1rem; margin-right: -1rem;">
						<table class="table table-striped table-borderless align-middle">
							<thead>
								<tr>
									<th scope="col" class="ps-3">Кол-во выписок</th>
									<th scope="col">Стоимость</th>
									<th scope="col" class="pe-3">&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="ps-3">10</th>
									<td>
										400 руб.
										<div class="clear text-secondary fs-5">(40 руб. за выписку)</div>
									</td>
									<td class="text-end pe-3">
										<div class="btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-3"
											 style="padding: 0.75rem 0.95rem;">
											<img :src="require('@/assets/icons/shopping-cart.svg')" alt="Заказать" width="24" height="24">
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row" class="ps-3">20</th>
									<td>
										700 руб.
										<div class="clear text-secondary fs-5">(35 руб. за выписку)</div>
									</td>
									<td class="text-end pe-3">
										<div class="btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-3"
											 style="padding: 0.75rem 0.95rem;">
											<img :src="require('@/assets/icons/shopping-cart.svg')" alt="Заказать" width="24" height="24">
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row" class="ps-3">30</th>
									<td>
										900 руб.
										<div class="clear text-secondary fs-5">(30 руб. за выписку)</div>
									</td>
									<td class="text-end pe-3">
										<div class="btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-3"
											 style="padding: 0.75rem 0.95rem;">
											<img :src="require('@/assets/icons/shopping-cart.svg')" alt="Заказать" width="24" height="24">
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="modal-footer border-0 p-1">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
					>
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},

	computed: {

	},
}
</script>