<template>
	<div class="container px-20">
		<div class="row-fluid">
			<div class="col">
				<div class="btn-toolbar btn-group object-actions justify-content-center mb-3"
					 role="toolbar"
					 aria-label="Toolbar with button groups"
					 :data-selected-count="this.selected_count">
					<!-- div class="btn-group me-2" role="group" aria-label="Toolbar button group" -->
					<button v-if="this.$props.currentSection !== 'archive'"
							type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="В PDF-презентацию"
							:disabled="this.selected_count == 0"
							@click="this.$emit('sendObjectsToPDF')">
						<img :src="require('@/assets/icons/pdf-file.svg')" alt="PDF" width="20" height="20">
					</button>
					<button type="button"
							class="btn btn-light border-1 rounded-3"
							title="Выбрать всё"
							@click="this.$emit('checkObjects', true)">
						<img :src="require('@/assets/icons/select-icon.png')" alt="Выбрать всё" width="18" height="18">
					</button>
					<button type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="Снять выделение"
							:disabled="this.selected_count == 0"
							@click="this.$emit('uncheckObjects', false)">
						<img :src="require('@/assets/icons/deselect-icon.png')" alt="Снять выделение" width="18" height="18">
					</button>
					<button type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="Очистить выделение"
							:disabled="this.selected_count == 0"
							@click="this.$emit('clearAllSelected')">
						<img :src="require('@/assets/icons/eraser.svg')" alt="Очистить выделение" width="20" height="20">
					</button>
					<button v-if="this.$props.currentSection !== 'search' && this.$props.currentSection !== 'archive'"
							type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="Перенести в архив"
							:disabled="this.selected_count == 0"
							@click="this.archiveObjects()">
						<img :src="require('@/assets/icons/folder.svg')" alt="Архив" width="20" height="20">
					</button>
					<button v-if="this.$props.currentSection !== 'search' && this.$props.currentSection == 'archive'"
							type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="Восстановить из архива"
							:disabled="this.selected_count == 0"
							@click="this.$emit('archiveObjects', true)">
						<img :src="require('@/assets/icons/back-icon.svg')" alt="Восстановить" width="20" height="20">
					</button>
					<button v-if="this.$props.currentSection !== 'search' && this.$props.currentSection !== 'archive'"
							type="button"
							class="btn btn-light border-1 rounded-3"
							:class="{'opacity-50': (this.selected_count == 0)}"
							title="Передать сотруднику"
							:disabled="this.selected_count == 0"
							@click="this.$emit('transferObjects')">
						<img :src="require('@/assets/icons/transfer.svg')" alt="Передать" width="20" height="20">
					</button>
					<!-- /div -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "@/services/CommonService";

	export default {
        name: "ObjectsToolbar",
		props: {
			currentSection: {type: String},
			selectedCount: {type: [String, Number]},
		},
        data() {
            return {

            }
        },
		methods: {
			archiveObjects() {
				if (confirm('Вы действительно желаете перенести объект(ы) в архив?')) {
					this.$emit('archiveObjects', false);
				}
			}
		},
		computed: {
			selected_count() {
				return parseInt(this.$props.selectedCount);
			},
		},
    };
</script>