<template>
	<div class="modal fade" id="requisitionsModal" tabindex="-1" aria-labelledby="Заявки по объекту" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Заявки по объекту</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column m-0 p-0">
					<div v-if="this.requisitions.length > 0">
						<RequestCard
							v-for="requisition in this.requisitions"
							:key="requisition.id"
							:requisitionItem="requisition"
							:objectsList="this.objects"
							:clientsList="this.clients" />
					</div>
					<div v-else-if="!this.requisitions">
						<EmptyList message="Заявки не найдены" />
					</div>
					<div v-else class="d-flex flex-column align-items-center">
						<Loader />
					</div>
				</div>
				<div class="modal-footer border-0 p-0">
					<Pagination :itemsList="[]" />
				</div>
			</div>
		</div>
	</div>

	<!--Модалки-->
	<!--История и задачи-->
	<HistoryTasksModal
		:fromSection="'requisitions'"
		:tempBlockTitle="'Подбор объекта с поиском по словам'" />

	<!--Смена этапов воронки-->
	<ChangeStageModal
		:title="'Закрыть этап Сделка прошла / Закрыть этап Встреча на объекте/офисе/презентация Zoom'"
		:fromSection="'requisitions'" />

</template>

<script>

import api from "@/api";
import Pagination from "@/components/common/Pagination";
import RequestCard from "@/components/requisitions/RequestCard";
import Loader from "@/components/common/Loader";
import CommonService from "@/services/CommonService";
import ChangeStageModal from "@/components/modals/ChangeStageModal"
import HistoryTasksModal from "@/components/modals/HistoryTasksModal"
import EmptyList from "@/components/common/EmptyList";


export default {
	name: "RequisitionsModal",
	components: {
		EmptyList,
		Loader,
		RequestCard,
		Pagination,
		ChangeStageModal,
		HistoryTasksModal
	},
	data() {
		return {
			object_id: null,
			requisitions: [],
			objects: [],
			clients: [],
		}
	},
	methods: {
		getRequisitions: function (object_id) {

			let params = {
				object_id: object_id || this.object_id
			};

			CommonService.log('debug', 'getRequisitions()', params);

			return api.get('/requisitions', {
				params: params
			})
			.then((response) => {

				CommonService.log('debug', 'getRequisitions()::axios', {response: response.data});

				let list = [];
				if (response.status == 200 && response.data.success) {

					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, requisition]) => {
							list.push(requisition);
						});
					}
				}

				console.debug('getRequisitions()', list);
				return list;
			}).catch(function (error) {

				CommonService.log('error', 'getRequisitions()::axios', error);

			});
		},
		getObjects(objects_ids) {
			return api.get('/objects', {
				params: {
					section: 'requisitions',
					objects_ids: objects_ids,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjects()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = response.data.objects;
					this.$store.commit('setReqObjects', list);
					return list;
				}

				return {};
			}).catch(function (error) {

				CommonService.log('error', 'getObjects()::axios', error);

			});
		},
		getClientsList(clients_ids) {
			return api.get('/common/clients', {
				params: {
					only_active: false,
					clients_ids: clients_ids,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getClientsList()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = response.data.list;
					this.$store.commit('setReqClients', list);
					return list;
				}

				return {};
			}).catch(function (error) {

				CommonService.log('error', 'getClientsList()::axios', error);

			});
		}
	},
	mounted() {
		let modal = document.getElementById('requisitionsModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.requisitions = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined")
					_this.object_id = target.getAttribute('data-bs-object-id');

				_this.getRequisitions(_this.object_id).then(data => {
					if (data.length) {
						_this.requisitions = data;

						let clients_ids = [];
						let objects_ids = [];
						data.forEach(req => {

							if (!CommonService.isEmpty(req.object_id)) {

								if (objects_ids.indexOf(req.object_id) == -1)
									objects_ids.push(req.object_id);

							}

							if (!CommonService.isEmpty(req.client_id)) {

								if (clients_ids.indexOf(req.client_id) == -1)
									clients_ids.push(req.client_id);

							}
						});

						if (objects_ids.length) {
							_this.getObjects(objects_ids).then(data => {
								if (data.length) {
									_this.objects = data;
									//_this.$store.commit('setReqObjects', _this.objects);
								} else {
									_this.objects = false;
								}
							});
						}

						if (clients_ids.length) {
							_this.getClientsList(clients_ids).then(data => {
								if (data.length) {
									_this.clients = data;
									//_this.$store.commit('setReqClients', _this.clients);
								} else {
									_this.clients = false;
								}
							});
						}
					} else {
						_this.requisitions = false;
					}
				});

				if (process.env.NODE_ENV == "development") {
					console.debug('requisitionsModal::show.bs.modal', {
						object_id: _this.object_id,
						requisitions: _this.requisitions,
						clients: _this.clients,
						objects: _this.objects,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
}
</script>