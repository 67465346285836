<template>
	<div class="modal fade" id="objectOnMapModal" ref="objectOnMapModal" tabindex="-1" aria-labelledby="Местоположение объекта на карте" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-1">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Объект на карте
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-if="this.map_coords_ready" class="modal-body d-flex flex-column p-1" :data-geo-lat="coords[0]" :data-geo-lng="coords[1]">
					<yandex-map :settings="settings"
								:coords="coords"
								:zoom="18"
								ref="ymap"
								ymap-class="yandex-map">

						<ymap-marker :coords="coords"
									 marker-id="123"
									 ref="ymarker"
									 hint-content="some hint" />

					</yandex-map>
				</div>
				<div v-else class="modal-body d-flex flex-column p-1" data-geo-lat="null" data-geo-lng="null">
					<Loader />
				</div>

				<div class="modal-footer border-0 p-1">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
					>
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import CommonService from "@/services/CommonService";
import Loader from "@/components/common/Loader";

export default {
	name: 'ObjectOnMapModal',
	props: {
		mapMarkerLat: {type: [ Number, String ], default: 55.7522},
		mapMarkerLng: {type: [ Number, String ], default: 37.6156},
	},
	data() {
		return {
			settings: {
				apiKey: this.$root.config.yandexMap.apiKey,
				lang: this.$root.config.yandexMap.lang,
				coordorder: this.$root.config.yandexMap.coordorder,
				enterprise: this.$root.config.yandexMap.enterprise,
				version: this.$root.config.yandexMap.version
			},
			coords: []
		};
	},
	components: {Loader, yandexMap, ymapMarker },
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
	},
	watch: {

	},
	mounted() {
		var _this = this;
		var modal = this.$refs.objectOnMapModal;
		modal.addEventListener('hidden.bs.modal', function (event) {
			_this.coords = [];
			_this.emitter.emit("global.modalClose", modal);
		});
		modal.addEventListener('show.bs.modal', function (event) {
			let target = event.relatedTarget
			let lat = target.getAttribute('data-bs-lat');
			let lng = target.getAttribute('data-bs-lng');

			setTimeout(() => {
				_this.coords = [parseFloat(lat), parseFloat(lng)];
			}, 600);

			CommonService.log('debug', 'objectOnMapModal::show.bs.modal', _this.coords);

			_this.emitter.emit("global.modalShown", modal);
		});

	},
	computed: {
		map_coords_ready() {
			return !this.isEmpty(this.coords);
		},
	}
}
</script>

<style lang="scss">
.modal {
	.modal-body {
		.yandex-map {
			width: 100%;
			min-height: 240px;
		}
	}
}
</style>