<template>
	<div class="modal fade" id="advertStatsModal" tabindex="-1" aria-labelledby="Отчет по рекламе" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Отчет по рекламе</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-nowrap-mask text-nowrap-mask-y px-2" style="margin: auto -2rem;">
					<div class="d-block text-wrap">
						<div v-if="this.isLoaded">
							<div class="d-flex flex-wrap border-dark border-top px-4 pt-3 bg-white mb-3">
								<div class="d-flex flex-fill mb-2"><span class="fs-3">Размещение через JCat и Zipal</span></div>
								<ul v-if="this.feeds.total" class="list-unstyled">
									<li v-for="(feed, index) in feeds" :key="index">
										<span v-if="feed.feed_name">
											<span class="text-dark-gray fw-normal">
												<a :href="feed.link"
												   class="link"
												   target="_blank">{{ feed.feed_name }}</a>, просмотров:
											</span>
											<span class="ms-2">{{ feed.views }}</span>
										</span>
										<span v-else-if="feeds.total" class="d-inline-flex mt-2">
											<b>Общее количество просмотров:</b> <span class="ms-2">{{ feeds.total }}</span>
										</span>
									</li>
								</ul>
								<div v-else class="d-flex flex-fill w-100 mt-1 mb-3">
									<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
									<span class="text-dark-gray">
										Нет статистики размещения.
									</span>
								</div>
							</div>
							<div v-if="!this.isEmpty(this.stats.avito)" class="d-flex border-dark border-top px-4 pt-3 bg-white">
								<div class="w-100" v-if="stats.avito.api && (stats.avito.views || stats.avito.contacts || stats.avito.favorites || stats.avito.calls)">
									<div class="d-inline-flex mb-2 popover-container">
										<a v-if="stats.avito.url" :href="stats.avito.url" class="link fs-3" target="_blank" v-text="'Авито'" />
										<span v-else class="fs-3" v-text="'Авито'" />
										<span v-if="stats.avito.errors"
											  class="ms-2"
											  data-bs-toggle="popover"
											  data-bs-trigger="hover"
											  data-bs-html="true"
											  data-bs-class="popover-danger p-3 text-danger"
											  :data-bs-content="stats.avito.errors">
												<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" width="16" height="16" />
										</span>
									</div>
									<dl class="dl-horizontal w-100">
										<dt class="text-dark-gray fw-normal">Просмотров объвления:</dt>
										<dd v-html="stats.avito.views ?? not_available" />
										<dt class="text-dark-gray fw-normal">Просмотров контактов:</dt>
										<dd v-html="stats.avito.contacts ?? not_available" />
										<dt class="text-dark-gray fw-normal">Добавили в избранное:</dt>
										<dd v-html="stats.avito.favorites ?? not_available" />
										<dt class="text-dark-gray fw-normal">Звонков с сайта:</dt>
										<dd v-html="stats.avito.calls ?? not_available" />
									</dl>
								</div>
								<div class="w-100" v-else>
									<div class="d-inline-flex mb-2">
										<span class="fs-3" v-text="'Авито'" />
									</div>
									<div v-if="stats.avito.errors" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<ul class="list-unstyled">
											<li v-for="(error, index) in stats.avito.errors" :key="index" class="text-dark-gray" v-html="error"></li>
										</ul>
									</div>
									<div v-else-if="stats.avito.api && !services.avito" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Объект не размещается через XML-фид'" />
									</div>
									<div v-else-if="stats.avito.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Ожидается поступление информации...'" />
									</div>
									<div v-if="!stats.avito.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray">
											Не настроен доступ к API.<br/>
											Вы можете сделать это из <router-link to="/profile">настроек профиля</router-link>.
										</span>
									</div>
								</div>
							</div>
							<div v-if="!this.isEmpty(this.stats.cian)" class="d-flex border-dark border-top px-4 pt-3 bg-light-gray">
								<div class="w-100" v-if="stats.cian.api && (stats.cian.views || stats.cian.contacts || stats.cian.favorites || stats.cian.calls)">
									<div class="d-inline-flex mb-2 popover-container">
										<a v-if="stats.cian.url" :href="stats.cian.url" class="link fs-3" target="_blank" v-text="'ЦИАН'" />
										<span v-else class="fs-3" v-text="'ЦИАН'" />
										<span v-if="stats.cian.errors"
											  class="ms-2"
											  data-bs-toggle="popover"
											  data-bs-trigger="hover"
											  data-bs-html="true"
											  data-bs-class="popover-danger p-3 text-danger"
											  :data-bs-content="stats.cian.errors">
												<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" width="16" height="16" />
										</span>
									</div>
									<dl class="dl-horizontal w-100">
										<dt class="text-dark-gray fw-normal">Просмотров объвления:</dt>
										<dd v-html="stats.cian.views ?? not_available" />
										<dt class="text-dark-gray fw-normal">Просмотров контактов:</dt>
										<dd v-html="stats.cian.contacts ?? not_available" />
										<dt class="text-dark-gray fw-normal">Добавили в избранное:</dt>
										<dd v-html="stats.cian.favorites ?? not_available" />
										<dt class="text-dark-gray fw-normal">Звонков с сайта:</dt>
										<dd v-html="stats.cian.calls ?? not_available" />
									</dl>
								</div>
								<div class="w-100" v-else>
									<div class="d-inline-flex mb-2">
										<span class="fs-3" v-text="'ЦИАН'" />
									</div>
									<div v-if="stats.cian.errors" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<ul class="list-unstyled">
											<li v-for="(error, index) in stats.cian.errors" :key="index" class="text-dark-gray" v-html="error"></li>
										</ul>
									</div>
									<div v-else-if="stats.cian.api && !services.cian" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Объект не размещается через XML-фид'" />
									</div>
									<div v-else-if="stats.cian.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Ожидается поступление информации...'" />
									</div>
									<div v-if="!stats.cian.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray">
											Не настроен доступ к API.<br/>
											Вы можете сделать это из <router-link to="/profile">настроек профиля</router-link>.
										</span>
									</div>
								</div>
							</div>
							<div v-if="!this.isEmpty(this.stats.yandex)" class="d-flex border-dark border-top px-4 pt-3 bg-white">
								<div class="w-100" v-if="stats.yandex.api && (stats.yandex.views || stats.yandex.contacts || stats.yandex.favorites || stats.yandex.calls)">
									<div class="d-inline-flex mb-2 popover-container">
										<a v-if="stats.yandex.url" :href="stats.yandex.url" class="link fs-3" target="_blank" v-text="'Яндекс.Недвижимость'" />
										<span v-else class="fs-3" v-text="'Яндекс.Недвижимость'" />
										<span v-if="stats.yandex.errors"
											  class="ms-2"
											  data-bs-toggle="popover"
											  data-bs-trigger="hover"
											  data-bs-html="true"
											  data-bs-class="popover-danger p-3 text-danger"
											  :data-bs-content="stats.yandex.errors">
												<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" width="16" height="16" />
										</span>
									</div>
									<dl class="dl-horizontal w-100">
										<dt class="text-dark-gray fw-normal">Просмотров объвления:</dt>
										<dd v-html="stats.yandex.views ?? not_available" />
										<dt class="text-dark-gray fw-normal">Просмотров контактов:</dt>
										<dd v-html="stats.yandex.contacts ?? not_available" />
										<dt class="text-dark-gray fw-normal">Добавили в избранное:</dt>
										<dd v-html="stats.yandex.favorites ?? not_available" />
										<dt class="text-dark-gray fw-normal">Звонков с сайта:</dt>
										<dd v-html="stats.yandex.calls ?? not_available" />
									</dl>
								</div>
								<div class="w-100" v-else>
									<div class="d-inline-flex mb-2">
										<span class="fs-3" v-text="'Яндекс.Недвижимость'" />
									</div>
									<div v-if="stats.yandex.errors" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<ul class="list-unstyled">
											<li v-for="(error, index) in stats.yandex.errors" :key="index" class="text-dark-gray" v-html="error"></li>
										</ul>
									</div>
									<div v-else-if="stats.yandex.api && !services.yandex" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Объект не размещается через XML-фид'" />
									</div>
									<div v-else-if="stats.yandex.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray" v-html="'Ожидается поступление информации...'" />
									</div>
									<div v-if="!stats.yandex.api" class="d-flex mt-1 mb-3">
										<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
										<span class="text-dark-gray">
											Не настроен доступ к API.<br/>
											Вы можете сделать это из <router-link to="/profile">настроек профиля</router-link>.
										</span>
									</div>
								</div>
							</div>
							<div v-if="!this.isEmpty(this.stats.domclick)" class="d-flex border-dark border-top px-4 pt-3 bg-light-gray">
							<div class="w-100" v-if="stats.domclick.api && (stats.domclick.views || stats.domclick.contacts || stats.domclick.favorites || stats.domclick.calls)">
								<div class="d-inline-flex mb-2 popover-container">
									<a v-if="stats.domclick.url" :href="stats.domclick.url" class="link fs-3" target="_blank" v-text="'ДомКлик'" />
									<span v-else class="fs-3" v-text="'ДомКлик'" />
									<span v-if="stats.domclick.errors"
										  class="ms-2"
										  data-bs-toggle="popover"
										  data-bs-trigger="hover"
										  data-bs-html="true"
										  data-bs-class="popover-danger p-3 text-danger"
										  :data-bs-content="stats.domclick.errors">
											<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" width="16" height="16" />
									</span>
								</div>
								<dl class="dl-horizontal w-100">
									<dt class="text-dark-gray fw-normal">Просмотров объвления:</dt>
									<dd v-html="stats.domclick.views ?? not_available" />
									<dt class="text-dark-gray fw-normal">Просмотров контактов:</dt>
									<dd v-html="stats.domclick.contacts ?? not_available" />
									<dt class="text-dark-gray fw-normal">Добавили в избранное:</dt>
									<dd v-html="stats.domclick.favorites ?? not_available" />
									<dt class="text-dark-gray fw-normal">Звонков с сайта:</dt>
									<dd v-html="stats.domclick.calls ?? not_available" />
								</dl>
							</div>
							<div class="w-100" v-else>
								<div class="d-inline-flex mb-2">
									<span class="fs-3" v-text="'ДомКлик'" />
								</div>
								<div v-if="stats.domclick.errors" class="d-flex mt-1 mb-3">
									<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
									<ul class="list-unstyled">
										<li v-for="(error, index) in stats.domclick.errors" :key="index" class="text-dark-gray" v-html="error"></li>
									</ul>
								</div>
								<div v-else-if="stats.domclick.api && !services.domclick" class="d-flex mt-1 mb-3">
									<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
									<span class="text-dark-gray" v-html="'Объект не размещается через XML-фид'" />
								</div>
								<div v-else-if="stats.domclick.api" class="d-flex mt-1 mb-3">
									<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
									<span class="text-dark-gray" v-html="'Ожидается поступление информации...'" />
								</div>
								<div v-if="!stats.domclick.api" class="d-flex mt-1 mb-3">
									<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
									<span class="text-dark-gray">
										Не настроен доступ к API.<br/>
										Вы можете сделать это из <router-link to="/profile">настроек профиля</router-link>.
									</span>
								</div>
							</div>
						</div>
						</div>
						<Loader v-else />
						<div class="border-dark border-top px-4 pt-3 bg-white">
							<div class="d-flex flex-fill mt-1 mb-3">
								<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16">
								<span class="text-dark-gray">
									Формирование отчета JCat может занимать до 3-х дней после размещения объекта.
								</span>
							</div>
							<div class="d-flex flex-fill mt-1 mb-3">
								<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16">
								<span class="text-dark-gray">
									Статистика Авито, ЦИАН, ДомКлик и Яндекс.Недвижимость обновляется ежечасно.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal">
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommonService from "@/services/CommonService";
import api from "@/api";
import Loader from "@/components/common/Loader";

export default {
	components: {Loader},
	data() {
		return {
			object_id: null,
			feeds: [],
			stats: [],
			services: [],
			not_available: '-',
			isLoaded: false,
		}
	},
	methods: {
		getAdvertStats() {
			api.get('/objects/stats', {
				params: {
					object_id: this.object_id,
				}
			})
			.then((response) => {

				this.isLoaded = true;

				CommonService.log('debug', 'getAdvertStats()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					if (!CommonService.isEmpty(response.data.results['feeds']))
						this.feeds = response.data.results['feeds'];

					if (!CommonService.isEmpty(response.data.results['stats']))
						this.stats = response.data.results['stats'];

          if (!CommonService.isEmpty(response.data.results['services']))
            this.services = response.data.results['services'];

				}

			}).catch(function (error) {

				CommonService.log('error', 'getAdvertStats()::axios', error);

			});
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		initPopovers() {
			/*let containers = document.querySelectorAll('.popover-container');
			if (!this.isEmpty(containers)) {
				let selector = '[data-bs-toggle="popover"]'
				containers.forEach(container => {
					CommonService.popoversInit(container, selector)
				})
			}*/
			let document = this.$el;
			CommonService.popoversInit(document);

			setTimeout(() => {
				let containers = document.querySelectorAll('.popover-container');
				if (!this.isEmpty(containers)) {
					let selector = '[data-bs-toggle="popover"]'
					containers.forEach(container => {
						CommonService.popoversInit(container, selector)
					})
				}
			}, 1000);
		},
	},
	mounted() {

		let modal = document.getElementById('advertStatsModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.feeds = [];
				_this.stats = [];
				_this.isLoaded = false;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined")
					_this.object_id = target.getAttribute('data-bs-object-id');

				_this.getAdvertStats();
				_this.initPopovers();

				if (process.env.NODE_ENV == "development") {
					console.debug('advertStatsModal::show.bs.modal', {
						object_id: _this.object_id,
						feeds: _this.feeds,
						stats: _this.stats,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {

	},
}
</script>