<template>
	<div class="modal fade" id="cancelDelegateObjectModal" tabindex="-1" aria-labelledby="Выбор нового сотрудника для выбранного объекта" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span v-if="objectsCount > 1" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Вы действительно хотите отменить передачу объектов?</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Вы действительно хотите отменить передачу объекта?</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
						@click="initCancelTransfer()">
						Отменить передачу
					</button>
				</div>
			</div>
		</div>
	</div>

	<ResultsModal
		id="transferObject"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>
</template>

<script>
import CommonService from "@/services/CommonService";
import api from "@/api";

export default {
	name: "CancelDelegateObjectModal",
	props: {
		objectsIds: { type: [Array, String] },
	},
	data() {
		return {
			objects_ids: (typeof this.objectsIds !== "undefined") ? this.objectsIds : [],
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	methods: {
		initCancelTransfer() {

			if (!CommonService.isEmpty(this.objects_ids)) {
				api.put('/objects/transfer', {
					objects_ids: this.objects_ids,
					cancel_transfer: true
				}).then((response) => {
					CommonService.log('debug', 'initCancelTransfer()::axios', {response});

					if (response.status == 200 && response.data.success) {
						this.resultsModalState.state = 'success';
						this.resultsModalState.title = 'OK';
						this.resultsModalState.message = 'Передача объекта была успешно отменена!';
						this.$emit('objectsTransferCanceled', {objects_ids: this.objects_ids});
					} else {
						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';
						this.resultsModalState.message = 'Передача объекта не была отменена.';
					}

				}).catch(function (error) {

					CommonService.log('error', 'initCancelTransfer()::axios', error);

					this.resultsModalState.state = 'error';
					this.resultsModalState.title = 'Произошла ошибка!';
					this.resultsModalState.message = 'Передача объекта не была отменена.';

				});
			} else {
				this.resultsModalState.state = 'error';
				this.resultsModalState.title = 'Ошибка!';
				this.resultsModalState.message = 'Необходимо выбрать объект для отмены передачи.';
			}
		},
	},
	mounted() {

		let modal = document.getElementById('cancelDelegateObjectModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.objects_ids = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined") {
					_this.objects_ids = [];
					let object_id = target.getAttribute('data-bs-object-id');
					if (typeof object_id !== "undefined") {
						_this.objects_ids.push(object_id);
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('cancelDelegateObjectModal::show.bs.modal', {
						objects_ids: _this.objects_ids
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		objectsCount() {
			return this.objects_ids.length
		},
	},
}
</script>