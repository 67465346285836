<template>
	<div>
		<div class="modal fade" id="listEgrnModal" ref="listEgrnModal" tabindex="-1" aria-labelledby="Список выписок из ЕГРН" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Выписки из ЕГРН
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column p-1">
						<div class="row-fluid">
							<div class="col d-flex align-items-center">
								<span class="text-secondary">Доступно выписок: {{ this.egrn_count }}</span>
								<a v-if="this.egrn_count > 0" href="javascript:{}"
								   class="btn btn-outline-warning rounded-3 ms-auto me-0"
								   data-bs-toggle="modal"
								   data-bs-target="#orderEgrnModal"
								   :data-bs-object-id="this.object_id">
									Новая выписка
								</a>
							</div>
						</div>
						<div v-show="!this.isEmpty(this.list)"
							 class="table-responsive mt-3">
							<table class="table table-striped">
								<thead class="table-light">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Дата/время</th>
										<th scope="col">Тип</th>
										<th scope="col">Статус</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in this.list" :key="index">
										<th scope="row">{{  index+1 }}</th>
										<td>{{ item.created_at }}</td>
										<td v-if="item.type == 'simple'">Выписка из ЕГРН</td>
										<td v-else-if="item.type == 'move'">Выписка из ЕГРН о переходе прав</td>
										<td v-else>-/-</td>
										<td v-if="item.is_done">
											<a :href="item.request_file"
											   class="link link-dashed"
											   target="_blank"
											   :download="true">
												Скачать
											</a>
										</td>
										<td v-else>В&nbsp;процессе</td>
									</tr>
								</tbody>
								<caption>Выписок по объекту: {{ this.list.length }}</caption>
							</table>
						</div>
					</div>
					<div class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-secondary col-12 rounded-3 py-3 text-white fs-3"
								data-bs-dismiss="modal">
							Закрыть
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";

export default {
	name: 'ListEgrnModal',
	data() {
		return {
			object_id: null,
			list: []
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getListEgrn(object_id) {
			api.get('/object/egrn', {
				params: {
					object_id: object_id
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getListEgrn()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					if (!this.isEmpty(response.data.list)) {
						this.list = response.data.list;
					}
				}
			}).catch(function (error) {

				CommonService.log('error', 'getListEgrn()::axios', error);

			});
		}
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.listEgrnModal;
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.list = [];

				let object_id = target.getAttribute('data-bs-object-id');
				if (typeof object_id !== "undefined") {
					_this.object_id = object_id;
					_this.getListEgrn(object_id);
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('listEgrnModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		egrn_count () {
			if (!this.isEmpty(this.$store.getters.userInfo)) {
				if (typeof this.$store.getters.userInfo.services.egrn_count !== "undefined") {
					return this.$store.getters.userInfo.services.egrn_count;
				}
			}

			return 0;
		}
	},
}
</script>