<template>
	<div class="modal fade" id="advertCoastModal" tabindex="-1" aria-labelledby="Затраты на рекламу" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Затраты на рекламу</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body px-2" style="margin: auto -2rem;">
					<div v-if="this.isLoaded" class="d-flex flex-wrap border-dark border-top px-4 pt-3 bg-white mb-3">
						<div v-if="!this.isEmpty(this.stats)"
							 class="accordion w-100 text-nowrap-mask text-nowrap-mask-y"
							 id="advertCoastList">
							<div v-for="(list, datetime, index) in stats"
								 :key="index"
								 class="accordion-item text-wrap">
								<h2 class="accordion-header"
									:id="'advertCoastHeading_'+index">
									<button class="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											:data-bs-target="'#advertCoastCollapse_'+index"
											:aria-expanded="(index) ? 'true' : 'false'"
											:aria-controls="'advertCoastCollapse_'+index">
										<b>{{ datetime }}</b>
										<span v-if="list.amount" class="ms-2 px-2 badge bg-danger rounded-3"
											  v-text="this.stringifyPrice(list.amount)" />
									</button>
								</h2>
								<div :id="'advertCoastCollapse_'+index"
									 class="accordion-collapse collapse"
									 :class="(index) ? 'show' : ''"
									 :aria-labelledby="'advertCoastHeading_'+index"
									 data-bs-parent="#advertCoastList">
									<div class="accordion-body pb-0">
										<ul class="list-unstyled">
											<li v-for="(stat, time, index2) of list"
												:key="index2">
												<span v-if="time !== 'amount'">
													<ul class="list-unstyled">
														<li v-for="(item, index3) of stat"
															:key="index3"
															class="mb-2">
															<span class="text-dark fw-normal">
																<span><span class="text-info">{{ time }}</span> - {{ item.target }}</span>
																<span v-if="item.days" class="fw-normal">,
																	срок: {{ this.declOfNumber(item.days, ['%d день', '%d дня', '%d дней']) }} (до {{ item.expired }})
																</span>
																<span v-if="this.inArray(item.type, ['promotion', 'regular'])" class="fw-normal">,
																	стоимость: <a href="javascript:{}"
																				  :ref="'advert_price_'+ item.id + '_' + item.destination +'_link'"
																				  @click="this.showPriceInput('advert_price_' + item.id + '_' + item.destination)">
																		{{ this.stringifyPrice(item.price) }}
																		<img :src="require('@/assets/icons/pen-icon.svg')" class="pb-1" alt="Редактировать" width="14" height="14">
																	</a>
																	<span :ref="'advert_price_'+ item.id + '_' + item.destination +'_input'" style="display: none">
																		<InputText :id="'advert_price_'+ item.id + '_' + item.destination +'_input'"
																				   :ref="'advert_price_'+ item.id + '_' + item.destination +'_input'"
																				   :inputLabel="false"
																				   :inputValue="item.price"
																				   inputType="number"
																				   inputClass="rounded-1 mb-0"
																				   inputWrapClass="flex-column mt-2 mb-2"
																				   inputAppend=" руб."
																				   @change="(event) => { item.price = event.target.value }"/>
																		<a href="javascript:{}"
																		   @click="() => { item.price = this.editPriceInput(item.id, this.object_id, item.type ,item.destination, item.price, 'advert_price_' + item.id + '_' + item.destination) }">
																			<img :src="require('@/assets/icons/save-icon.svg')"
																			 class="d-block"
																			 alt="Сохранить"
																			 width="14"
																			 height="14">
																		</a>
																	</span>
																</span>
																<span v-else class="fw-normal"
																	  :class="{'text-success': (item.price == 0)}"
																	  v-text="(item.price > 0) ? ', стоимость: ' + this.stringifyPrice(item.price) + '.' : ', бесплатно.'" />
																<sup :ref="'advert_price_'+ item.id + '_' + item.destination +'_publisher'" class="text-dark-gray fw-normal ps-2">{{ '[' + item.publisher + ']' }}</sup>
															</span>
															<span v-if="!this.isEmpty(item.unpublished_at) && !this.isEmpty(item.unpublisher)" class="text-dark fw-normal">
																<br/>
																Снято с публикации {{ item.unpublished_at }}<sup :ref="'advert_price_'+ item.id + '_' + item.destination +'_unpublisher'" class="text-dark-gray fw-normal ps-2">{{ '[' + item.unpublisher + ']' }}</sup>
															</span>
														</li>
													</ul>
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div v-if="this.total" class="mt-3 pb-4 fs-3">
								Всего затрачено: {{ this.declOfNumber(this.total, ['%s рубль', '%s рубля', '%s рублей']) }}
							</div>
						</div>
						<div v-else class="d-flex align-content-center align-items-center flex-fill w-100 mt-3 mb-1">
							<img :src="require('@/assets/icons/warning.svg')" alt="Ошибка" class="me-2" style="margin-top: 2px;" width="16" height="16" />
							<span class="text-dark-gray">
								Нет статистики по затратам для данного объекта.
							</span>
						</div>
					</div>
					<Loader v-else />
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
					>
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import Loader from "@/components/common/Loader";
import InputText from "@/components/inputs/InputText";

export default {
	components: {InputText, Loader},
	data() {
		return {
			object_id: null,
			stats: [],
			total: 0,
			isLoaded: false,
		}
	},
	methods: {
		getAdvertCosts() {
			api.get('/adverts/object/costs', {
				params: {
					object_id: this.object_id,
				}
			})
			.then((response) => {

				this.isLoaded = true;

				CommonService.log('debug', 'getAdvertCosts()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					if (!CommonService.isEmpty(response.data.results['stats'])) {
						this.stats = response.data.results['stats'];
						this.total = response.data.results['total'];
					}

				}

			}).catch(function (error) {

				CommonService.log('error', 'getAdvertCosts()::axios', error);

			});
		},
		stringifyPrice(price) {
			return CommonService.stringifyPrice(price);
		},
		showPriceInput(target) {

			let link = this.$refs[target + '_link'];
			let input = this.$refs[target + '_input'];
			let publisher = this.$refs[target + '_publisher'];

			console.info(target, link, input);

			if (link)
				link.style = "display:none";

			if (input)
				input.style = "display:inline-block";

			if (publisher)
				publisher.style = "display:none";

		},
		editPriceInput(id, object_id, type, destination, price, target) {

			let link = this.$refs[target + '_link'];
			let input = this.$refs[target + '_input'];
			let publisher = this.$refs[target + '_publisher'];

			CommonService.log('info', 'editPriceInput()::axios', {
				id: id,
				object_id: object_id,
				destination: destination,
				price: price,
			});

			let url = '/adverts/price';
			if (type == 'promotion')
				url = '/adverts/promotion/price';

			api.put(url, {
				id: id,
				object_id: object_id,
				destination: destination,
				price: price,
			}).then((response) => {

				CommonService.log('debug', 'editPriceInput()::axios', response.data);

				if (response.status == 200 && response.data.success) {

					if (link)
						link.style = "display:inline-block";

					if (input)
						input.style = "display:none";

					if (publisher)
						publisher.style = "display:inline-block";

				}
			}).catch(function (error) {

				CommonService.log('error', 'editPriceInput()::axios', error);

			});

			return price;
		},
		declOfNumber(number, words) {
			return CommonService.declOfNumber(number, words);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
	},
	mounted() {

		let modal = document.getElementById('advertCoastModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.stats = [];
				_this.isLoaded = false;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined")
					_this.object_id = target.getAttribute('data-bs-object-id');

				_this.getAdvertCosts();

				if (process.env.NODE_ENV == "development") {
					console.debug('advertCoastModal::show.bs.modal', {
						object_id: _this.object_id,
						stats: _this.stats,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {

	},
}
</script>