<template>
	<div>
		<div class="modal fade" id="objectsAnalyticsModal" ref="objectsAnalyticsModal" tabindex="-1" aria-labelledby="Аналитика по конкурентам" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Аналитика по конкурентам
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column p-1">
						<div class="row-fluid row-cols-1">
							<div v-if="!this.isEmpty(this.objectsList)" class="col px-20">
								<ObjectCard
									v-for="object of this.objectsList"
									:objectItem="object"
									:key="object.id" />
							</div>
							<div v-else-if="this.isEmpty(this.objectsList)" class="col px-20 d-flex flex-column align-items-center">
								<EmptyList message="Объекты не найдены" />
							</div>
							<Loader v-else />
						</div>
					</div>
					<div class="modal-footer border-0 p-1">
						<Pagination v-if="this.pagination"
									:initialPage="this.pagination.current"
									:itemsList="this.pagination.items"
									:labels="{
										first: false,
										last: false,
										previous: false,
										next: false
									}"
									:pageSize="this.pagination.per_page"
									:disableDefaultStyles="true"
									@changePage="(page) => this.getAnalytics(page, this.pagination.per_page)"
									:maxPages="3" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import Pagination from "@/components/common/Pagination";
import CommonService from "@/services/CommonService";
import api from "@/api";
import Loader from "@/components/common/Loader";
import ObjectCard from "@/components/objects/ObjectCard";
import EmptyList from "@/components/common/EmptyList";

export default {
	name: 'ObjectsAnalyticsModal',
	components: {EmptyList, ObjectCard, Loader, Pagination},
	data() {
		return {
			object_id: null,
			objects: false,
			counters: false,
			pagination: false,
		}
	},
	methods: {
		getAnalytics (page, per_page) {
			api.get('/objects/analytics', {
				params: {
					object_id: this.object_id,
					page: (typeof page != "undefined") ? page : 1,
					per_page: (typeof per_page != "undefined") ? per_page : 10,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getAnalytics()::axios', {response: response.data});

				if (response.status == 200 && response.data.success)
					this.objects = response.data.objects;

			}).catch(function (error) {

				CommonService.log('error', 'getAnalytics()::axios', error);

			});
		},
		isEmpty(data) {
			return CommonService.isEmpty(data)
		}
	},
	mounted() {

		let modal = document.getElementById('objectsAnalyticsModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.objects = [];
				_this.counters = [];
				_this.pagination = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				let target = event.relatedTarget
				if (typeof target !== "undefined")
					_this.object_id = target.getAttribute('data-bs-object-id');

				_this.getAnalytics();

				if (process.env.NODE_ENV == "development") {
					console.debug('objectsAnalyticsModal::show.bs.modal', {
						object_id: _this.object_id,
						objects: _this.objects,
						counters: _this.counters,
						pagination: _this.pagination,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		objectsList() {
			return this.objects || [];
		}
	},
}
</script>