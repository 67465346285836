<template>
	<div class="modal fade" id="customFieldsFilterModal" tabindex="-1" aria-labelledby="Фильтрация по дополнительным полям" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-1">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Дополнительные поля
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-1">
					<CustomFieldsList :custom-fields="this.custom_fields_list" :is_filter="true" 
									  @setValue="(data) => this.setField(data.name, data.value)" />
				</div>
				<div class="modal-footer border-0 p-1">
					<button type="button"
							class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
							data-bs-dismiss="modal"
							@click="this.setupFields">
						Применить
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CustomFieldsList from "@/components/common/CustomFieldsList";
import api from "@/api";
import CommonService from "@/services/CommonService";

export default {
	name: 'CustomFieldsFilterModal',
	data() {
		return {
			custom_fields: [],
			custom_fields_list: null,
		}
	},
	components: {
		CustomFieldsList
	},
	methods: {
		getCustomFields() {
			return api.get('/objects/custom-fields', {
				params: {
					is_active: true,
					is_filtrable: true,
				}
			}).then((response) => {

				CommonService.log('debug', 'getCustomFields()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = {
						fields: response.data.fields,
						blocks: response.data.blocks,
						types: response.data.types,
					};
					this.$store.commit('setCustomFieldsList', list);
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getCustomFields()::axios', error);

			});
		},
		setField(field_name, field_value) {

			if (process.env.NODE_ENV == "development")
				console.debug('setValue()', {
					[field_name]: field_value,
				});

			this.$emit('setField', {
				'name': field_name,
				'value': field_value,
			});

			if (!this.isEmpty(field_name)) {
				
				if(this.custom_fields[field_name]) {
					if(this.isEmpty(field_value) || field_value == 0)
						delete this.custom_fields[field_name];
					else
						this.custom_fields[field_name] = field_value
				} else {	
					this.custom_fields = {
						...{
							[field_name]: field_value
						},
						...this.custom_fields
					};
				}
				
			}
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		setupFields() {

			CommonService.log('debug', 'setupFields()', this.custom_fields);

			this.$emit('setupFields', this.custom_fields);
		},
	},
	mounted() {
		if (!this.isEmpty(this.$store.state.custom_fields_list))
			this.custom_fields_list = this.$store.state.custom_fields_list;
		else
			this.getCustomFields('custom-fields').then(data => { this.custom_fields_list = data });
	},
	computed: {

	},
}
</script>